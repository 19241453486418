import React from "react"
import Helmet from "react-helmet"
import { Global, css } from "@emotion/core"
import Header from "./header"
import Footer from "./footer"
import { useLocation } from "@reach/router"

const Layout = props => {
  const { varHead = true, varFoot = true, logOut = false, InfoPath } = props
  return (
    <>
      <Global
        styles={css`
          html {
            overflow-x: hidden;
            height: 100%;
          }
          body {
            font-family: "Rubik", sans-serif;
            zoom: 90%;
            overflow-x: hidden;
            height: 100%;
          }
        `}
      />
      <Helmet>
        <title>Enlacce</title>
        <meta
          name="description"
          content="Ofrecer a los vendedores de Grupo Enlacce la función de login para que puedan acceder a la información clave para mostrar a sus posibles clientes"
        />
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        ></link>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        ></link>
      </Helmet>

      {varHead && <Header logOut={logOut} InfoPath={InfoPath} />}

      {props.children}

      {varFoot && <Footer logOut={logOut} />}
    </>
  )
}

export default ({ children, pageContext }) => {
  const Path = useLocation()

  if (pageContext.layout === "calculator") {
    return (
      <Layout logOut={true} InfoPath={Path}>
        {children}
      </Layout>
    )
  }
  if (pageContext.layout === "no-nav") {
    return (
      <Layout varHead={false} varFoot={false} InfoPath={Path}>
        {children}
      </Layout>
    )
  }
  if (pageContext.layout === "no-navbar") {
    return (
      <Layout varNav={false} varChat={false} InfoPath={Path}>
        {children}
      </Layout>
    )
  }
  if (pageContext.layout === "no-chatbot") {
    return (
      <Layout varChat={false} InfoPath={Path}>
        {children}
      </Layout>
    )
  }
  return <Layout InfoPath={Path}>{children}</Layout>
}
