import React, { useState } from "react"
import { Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import PersonIcon from "@material-ui/icons/Person"
import CalculatorIcon from "../../images/calculator.svg"
import PercentageIcon from "../../images/porcentaje.svg"
import CoinIcon from "../../images/coin.svg"
import GraficIcon from "../../images/grafica.svg"
import { Link } from "gatsby"
import { logout, getCurrentUser } from "../../utils/auth"
import { navigate } from "@reach/router"

const Header = props => {
  const images = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "LogoEnlacce.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      menu: file(relativePath: { eq: "menu.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { logOut } = props
  const [items, setItems] = useState(false)
  const user = getCurrentUser()
  const { id_Role } = user

  const changeItems = bool => {
    if (bool) {
      setItems(bool)
    }
    setTimeout(() => {
      setItems(bool)
    }, 300)
  }

  if (id_Role === "5edfa9168ef939485483df3e") {
    return (
      <header
        css={css`
          top: 0;
          height: 15%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 2;
        `}
      >
        <div
          css={css`
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <a href="/app/home">
            <img
              css={css`
                width: 50%;
                height: auto;
                cursor: pointer;
              `}
              src={images.logo.sharp.fluid.src}
              alt="Logo_Enlacce"
            />
          </a>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {logOut && (
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 30px !important;
                  font-weight: 700 !important;
                  padding-right: 20px;
                  cursor: pointer;
                `}
                onClick={() => logout(() => navigate("/Login"))}
              >
                LOGOUT
              </Typography>
            )}
            <div
              css={css`
                width: 60px;
                height: 60px;
                & :hover > div {
                  opacity: 1;
                }
              `}
              onMouseOver={() => changeItems(true)}
              onMouseLeave={() => changeItems(false)}
            >
              <Image
                css={css`
                  width: 60px;
                  height: auto;
                `}
                tag="Section"
                fluid={images.menu.sharp.fluid}
                fadeIn="soft"
              ></Image>
              <div
                css={css`
                  width: 60px;
                  border-radius: 40px;
                  margin-top: -60px;
                  transition: 0.3s;
                  opacity: 0;
                  display: flex;
                  justify-content: flex-end;
                  background-color: #1b5196;
                  flex-direction: column;
                `}
              >
                {items && (
                  <div>
                    <div>
                      <Image
                        css={css`
                          width: 60px;
                          height: auto;
                        `}
                        tag="Section"
                        fluid={images.menu.sharp.fluid}
                        fadeIn="soft"
                      ></Image>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column;
                      `}
                    >
                      <Link
                        to="/app/calculator"
                        css={css`
                          height: 50px;
                          width: auto;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          margin-bottom: 5px;
                          text-decoration: none;
                        `}
                      >
                        <Typography
                          style={{ fontSize: "20px", fontWeight: 500 }}
                          css={css`
                            color: #1b5196;
                            white-space: nowrap;
                            font-size: 50px;
                            padding-right: 10px;
                          `}
                        >
                          Simulador
                        </Typography>
                        <div
                          css={css`
                            min-width: 60px !important;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <img src={CalculatorIcon} alt="CalculatorIcon" />
                        </div>
                      </Link>
                      <Link
                        to="/app/values"
                        css={css`
                          height: 50px;
                          width: auto;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          margin-bottom: 5px;
                          text-decoration: none;
                        `}
                      >
                        <Typography
                          style={{ fontSize: "20px", fontWeight: 500 }}
                          css={css`
                            color: #1b5196;
                            white-space: nowrap;
                            font-size: 50px;
                            padding-right: 10px;
                          `}
                        >
                          Valores
                        </Typography>
                        <div
                          css={css`
                            min-width: 60px !important;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <img src={PercentageIcon} alt="PercentageIcon" />
                        </div>
                      </Link>
                      <Link
                        to="/app/subsides"
                        css={css`
                          height: 50px;
                          width: auto;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          margin-bottom: 5px;
                          text-decoration: none;
                        `}
                      >
                        <Typography
                          style={{ fontSize: "20px", fontWeight: 500 }}
                          css={css`
                            color: #1b5196;
                            white-space: nowrap;
                            font-size: 50px;
                            padding-right: 10px;
                          `}
                        >
                          Subsidios
                        </Typography>
                        <div
                          css={css`
                            min-width: 60px !important;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <img src={CoinIcon} alt="CoinIcon" />
                        </div>
                      </Link>
                      <Link
                        to="/app/isr"
                        css={css`
                          height: 50px;
                          width: auto;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          margin-bottom: 5px;
                          text-decoration: none;
                        `}
                      >
                        <Typography
                          style={{ fontSize: "20px", fontWeight: 500 }}
                          css={css`
                            color: #1b5196;
                            white-space: nowrap;
                            font-size: 50px;
                            padding-right: 10px;
                          `}
                        >
                          ISR
                        </Typography>
                        <div
                          css={css`
                            min-width: 60px !important;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <img src={GraficIcon} alt="GraficIcon" />
                        </div>
                      </Link>

                      <Link
                        to="/app/users"
                        css={css`
                          height: 50px;
                          width: auto;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          margin-bottom: 5px;
                          text-decoration: none;
                        `}
                      >
                        <Typography
                          style={{ fontSize: "20px", fontWeight: 500 }}
                          css={css`
                            color: #1b5196;
                            white-space: nowrap;
                            font-size: 50px;
                            padding-right: 10px;
                          `}
                        >
                          Usuarios
                        </Typography>
                        <div
                          css={css`
                            min-width: 60px !important;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <PersonIcon
                            style={{ fontSize: 30, color: "#ffffff" }}
                          />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  } else {
    return (
      <header
        css={css`
          top: 0;
          height: 15%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          z-index: 2;
        `}
      >
        <div
          css={css`
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          <a href="/app/home">
            <img
              css={css`
                width: 50%;
                height: auto;
                cursor: pointer;
              `}
              src={images.logo.sharp.fluid.src}
              alt="Logo_Enlacce"
            />
          </a>
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            {logOut && (
              <Typography
                css={css`
                  color: #1b5196;
                  font-size: 30px !important;
                  font-weight: 700 !important;
                  padding-right: 20px;
                  cursor: pointer;
                `}
                onClick={() => logout(() => navigate("/Login"))}
              >
                LOGOUT
              </Typography>
            )}
            <div
              css={css`
                width: 60px;
                height: 60px;
                & :hover > div {
                  opacity: 1;
                }
              `}
              onMouseOver={() => changeItems(true)}
              onMouseLeave={() => changeItems(false)}
            >
              <Image
                css={css`
                  width: 60px;
                  height: auto;
                `}
                tag="Section"
                fluid={images.menu.sharp.fluid}
                fadeIn="soft"
              ></Image>
              <div
                css={css`
                  width: 60px;
                  border-radius: 40px;
                  margin-top: -60px;
                  transition: 0.3s;
                  opacity: 0;
                  display: flex;
                  justify-content: flex-end;
                  background-color: #1b5196;
                  flex-direction: column;
                `}
              >
                {items && (
                  <div>
                    <div>
                      <Image
                        css={css`
                          width: 60px;
                          height: auto;
                        `}
                        tag="Section"
                        fluid={images.menu.sharp.fluid}
                        fadeIn="soft"
                      ></Image>
                    </div>
                    <div
                      css={css`
                        display: flex;
                        justify-content: flex-end;
                        flex-direction: column;
                      `}
                    >
                      <Link
                        to="/app/calculator"
                        css={css`
                          height: 50px;
                          width: auto;
                          display: flex;
                          justify-content: flex-end;
                          align-items: center;
                          margin-bottom: 5px;
                          text-decoration: none;
                        `}
                      >
                        <Typography
                          style={{ fontSize: "20px", fontWeight: 500 }}
                          css={css`
                            color: #1b5196;
                            white-space: nowrap;
                            font-size: 50px;
                            padding-right: 10px;
                          `}
                        >
                          Simuladoree
                        </Typography>
                        <div
                          css={css`
                            min-width: 60px !important;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}
                        >
                          <img src={CalculatorIcon} alt="CalculatorIcon" />
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
