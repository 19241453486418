/**
 *  @version 1.0.1
 *  @author Springlabs - alexalemanhdz
 *  @description Componente para modales
 * 	@process 3
 */

/* REACT MODULES */
import React, { useEffect } from "react"
import PropTypes from "prop-types"

/* MATERIAL UI */
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Modal from "@material-ui/core/Modal"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"

/* LOCAL FILES */
import useModal from "../hooks/useModal"

/* STYLES */
const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
  },
  card: {
    whidt: "auto",
    height: "auto",
    backgroundColor: "white",
    position: "relative",
    top: "-5rem",
  },
  cardContent: {
    textAlign: "center",
    padding: "2rem 5rem !important",
    backgroundColor: theme.palette.white,
    borderRadius: "1rem",
    "& *": {
      margin: "1rem",
    },
    "& h1, h2, h3, h4, h5, h6": {
      color: theme.palette.secondary.dark,
    },
    "& p": {
      color: theme.palette.text.secondary,
    },
  },
  button: {
    textTransform: "none",
    fontWeight: "300",
    margin: "1rem",
  },
  hoverImage: {
    position: "absolute",
    maxWidth: "20rem",
    maxHeight: "20rem",
    bottom: "2rem",
    [theme.breakpoints.up("md")]: {
      left: "15rem",
    },
  },
  modalContainerBig: {
    backgroundColor: theme.palette.white,
    height: "70%",
    width: "50%",
    borderRadius: "9%",
  },
}))

const StyledButton = withStyles({
  root: {
    background: "linear-gradient(45deg, #00ADBB 30%, #60D6DF 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button)

function CustomModal(props) {
  const [open, handleOpen, handleClose] = useModal()
  const classes = useStyles()

  useEffect(() => {
    if (props.noButton) {
      handleOpen()
    }
  }, [])

  useEffect(() => {
    if (props.noButton) {
      handleOpen()
    }
  }, [])

  return (
    <div>
      {props.noButton ? null : (
        <StyledButton
          variant="contained"
          color="primary"
          onClick={handleOpen}
          className={classes.button}
        >
          {props.buttonLabel}
        </StyledButton>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        className={`${classes.modal} ${
          props.gradiant ? classes.gradiant : null
        }`}
        style={props.style}
      >
        <div
          className={
            props.Model === "Big"
              ? classes.modalContainerBig
              : classes.modalContainer
          }
        >
          {props.noDialog ? (
            <div>{props.children}</div>
          ) : (
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                {props.children}
                {props.okMessage ? (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={handleClose}
                  >
                    {props.okMessage}
                  </Button>
                ) : null}
              </CardContent>
            </Card>
          )}
          {props.hoverImagePath ? (
            <img
              alt="modal"
              className={classes.hoverImage}
              src={`img/${props.hoverImagePath}`}
            />
          ) : null}
        </div>
      </Modal>
    </div>
  )
}

CustomModal.propTypes = {
  buttonLabel: PropTypes.string,
  gradiant: PropTypes.bool,
  hoverImagePath: PropTypes.string,
  noButton: PropTypes.bool,
  noDialog: PropTypes.bool,
}

CustomModal.defaultProps = {
  buttonLabel: "Open modal",
  gradiant: false,
  hoverImagePath: "",
  noButton: false,
  noDialog: false,
}

export default CustomModal
