import React, { Fragment } from "react"
import { Button, Grid } from "@material-ui/core"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  ParrafoText: {
    textAlign: "justify",
  },
  Actualizacion: {
    color: "red",
    textAlign: "left",
  },
}))

export default function MaxWidthDialog({ open, handleClose }) {
  const classes = useStyles()
  return (
    <Fragment>
      <Dialog
        fullWidth={"lg"}
        maxWidth={"lg"}
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          Aviso de privacidad, términos y condiciones.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p className={classes.ParrafoText}>
              A. Identidad del Responsable GRUPO ENLACCE y sus filiales y/o
              subsidiarias, con domicilio en Río Manzanares #308 colonia Del
              Valle en San Pedro Garza García, Nuevo León, C.P.62220, es una
              empresa comprometida en proteger su privacidad. Nuestro
              responsable de Protección de Datos es el Departamento de Datos
              Personales y se ubica en el mismo domicilio, usted podrá
              contactarlo en el correo electrónico:
              <a href="mailto:coordinacion@enlacce.com">
                coordinacion@enlacce.com
              </a>
              <br /> <br />
              I. Datos Personales <br />
              <br /> Para los fines señalados en el presente Aviso de
              Privacidad, GRUPO ENLACCE podrá recabar sus datos personales de
              diversas formas: cuando la información es proporcionada
              directamente por usted; cuando visita los sitios de internet de
              GRUPO ENLACCE; cuando utiliza los servicios ofrecidos por GRUPO
              ENLACCE en línea por los datos que usted autoriza a tomar a GRUPO
              ENLACCE de sus redes sociales (Facebook, twitter, LinkedIn,
              google, etc.) o al conectarse al Wi-Fi que GRUPO ENLACCE en su
              caso le ofrezca; y cuando obtenemos información a través de otros
              medios distintos a los antes señalados y que están permitidos por
              las leyes aplicables. <br />
              <br />
              Los datos personales obtenidos por los medios antes indicados,
              son:
              <br />
              <br /> 1. Datos de identificación: nombre(s), apellido(s),
              domicilio, número telefónico de casa, celular y/o trabajo, estado
              civil, firma, correo electrónico, género, edad, fecha de
              nacimiento, nombre de usuario y contraseña. <br />
              <br />
              2. Datos Financieros: nombre del tarjetahabiente, número de
              tarjeta bancaria, fecha de expiración. <br />
              <br />
              3. Datos de Facturación: nombre o denominación o razón social,
              RFC, domicilio fiscal. <br />
              <br />
              C. Datos Personales Sensibles <br />
              <br />
              Se consideran datos personales sensibles aquellos datos personales
              que afecten a la esfera más íntima de su titular, o cuya
              utilización indebida pueda dar origen a discriminación o conlleve
              un riesgo grave para éste. En particular, se consideran sensibles
              aquellos que puedan revelar aspectos como origen racial o étnico,
              estado de salud presente y futuro, información genética, creencias
              religiosas, filosóficas y morales, afiliación sindical, opiniones
              políticas, preferencias sexuales. <br />
              <br />
              <br />
              D. Finalidad y Tratamiento de la Información Personal <br />
              <br />
              Finalidades que dan origen a la relación jurídica y son necesarias
              para la prestación de servicio: <br />
              <br />
              1. La información personal que nos proporciona será utilizada por
              GRUPO ENLACCE para prestar los servicios que usted le solicita.
              <br />
              2. GRUPO ENLACCE puede utilizar la información personal que nos
              proporciona para ofrecerle un mejor servicio. <br />
              <br />
              E. Limitación al Uso o Divulgación de Información Personal. En
              cualquier momento usted puede manifestar su negativa al
              tratamiento de las finalidades que no dan origen a la relación
              jurídica ni son necesarias para la prestación del servicio, lo
              cual podrá solicitar al correo electrónico&nbsp;
              <a href="mailto:coordinacion@enlacce.com">
                coordinacion@enlacce.com
              </a>
              , indicando el(los) correo electrónico(s) y/o número(s)
              telefónico(s); así mismo podrá revocar su consentimiento en los
              términos señalados en el inciso F. <br />
              <br />
              F. Del Ejercicio de los Derechos de Acceso, Rectificación,
              Cancelación y Oposición.
              <br />
              <br /> El acceso, rectificación, cancelación u oposición respecto
              a la información que proporcione a GRUPO ENLACCE señalados en la
              Ley Federal de Protección de Datos Personales en Posesión de los
              Particulares, o bien, la revocación del consentimiento que nos ha
              otorgado al tratamiento de sus datos personales, lo podrá hacer
              mediante entrega de la solicitud por escrito al Departamento de
              Datos Personales al siguiente domicilio: Río Manzanares #308
              colonia Del Valle en San Pedro Garza García, Nuevo León, de 9 a 14
              horas de lunes a viernes o al correo electrónico &nbsp;
              <a href="mailto:coordinacion@enlacce.com">
                coordinacion@enlacce.com
              </a>
              <br />
              <br />
              Dicha solicitud deberá contener cuando menos lo siguiente: (i)
              Nombre del Titular de los Datos Personales; (ii) Correo
              electrónico para recibir notificaciones; (iii) Documentos que
              acrediten su identidad o, en su caso, la representación legal del
              titular; (iv) Descripción clara y precisa de los datos personales
              respecto de los que se busca ejercer el derecho y del derecho que
              se pretende ejercer. (v) Cualquier otro elemento o documento que
              facilite la localización de los datos personales. En caso de que
              la solicitud no satisfaga alguno de los requisitos señalados
              anteriormente, el Departamento de Datos Personales podrá
              requerirle dentro de los 5 días a la recepción de la solicitud que
              aporte los elementos o documentos necesarios para dar trámite a la
              misma. Usted contará con 10 días para atender el requerimiento, en
              caso de no dar respuesta en dicho plazo, se tendrá por no
              presentada la solicitud correspondiente. <br />
              <br />
              Los plazos de atención serán de máximo 20 días contados desde la
              fecha en que se reciba la solicitud para comunicarle la
              determinación adoptada, a efecto de que, si resulta procedente,
              haga efectiva la misma dentro de los 15 días siguientes a la
              emisión de la respuesta. Con la finalidad de proteger su
              confidencialidad, GRUPO ENLACCE enviará la respuesta a su
              solicitud al correo electrónico que haya proporcionado para tal
              fin y la conservará a disposición del solicitante en el domicilio
              señalado en el párrafo anterior. <br />
              <br />
              G. Control y Seguridad de información personal. <br />
              <br />
              GRUPO ENLACCE se compromete a tomar las medidas necesarias para
              proteger la información recopilada, utilizando tecnologías de
              seguridad y procedimientos de control en el acceso, uso o
              divulgación de su información personal sin autorización, por
              ejemplo, almacenando la información personal proporcionada en
              servidores ubicados en Centros de Datos que cuentan con controles
              de acceso limitado. Sin embargo, ningún sistema de seguridad o de
              transmisión de datos del cual la empresa no tenga el control
              absoluto y/o tenga dependencia con internet puede garantizar que
              sea totalmente seguro. <br />
              <br />
              H. Medios electrónicos y cookies. <br />
              <br />
              En el supuesto de que usted utilice medios electrónicos en
              relación a sus datos personales se generarán a efecto de
              proporcionarle un mejor servicio cookies. Los cookies son pequeñas
              piezas de información que son enviadas por el sitio Web a su
              navegador. Los cookies se almacenan en el disco duro de su equipo
              y se utilizan para determinar sus preferencias cuando se conecta a
              los servicios de nuestros sitios, así como para rastrear
              determinados comportamientos o actividades llevadas a cabo por
              usted dentro de nuestros sitios. En algunas secciones de nuestro
              sitio requerimos que el cliente tenga habilitados los cookies ya
              que algunas de las funcionalidades requieren de éstas para
              trabajar. Los cookies nos permiten: a) reconocerlo al momento de
              entrar a nuestros sitios y ofrecerle de una experiencia
              personalizada, b) conocer la configuración personal del sitio
              especificada por usted, por ejemplo, los cookies nos permiten
              detectar el ancho de banda que usted ha seleccionado al momento de
              ingresar al home page de nuestros sitios, de tal forma que sabemos
              qué tipo de información es aconsejable descargar, c) calcular el
              tamaño de nuestra audiencia y medir algunos parámetros de tráfico,
              pues cada navegador que obtiene acceso a nuestros sitios adquiere
              un cookie que se usa para determinar la frecuencia de uso y las
              secciones de los sitios visitadas, reflejando así sus hábitos y
              preferencias, información que nos es útil para mejorar el
              contenido, los titulares y las promociones para los usuarios. Los
              cookies también nos ayudan a rastrear algunas actividades, por
              ejemplo, en algunas de las encuestas que lanzamos en línea,
              podemos utilizar cookies para detectar si el usuario ya ha llenado
              la encuesta y evitar desplegarla nuevamente, en caso de que lo
              haya hecho. El botón de “ayuda” que se encuentra en la barra de
              herramientas de la mayoría de los navegadores, le dirá cómo evitar
              aceptar nuevos cookies, cómo hacer que el navegador le notifique
              cuando recibe un nuevo cookie o cómo deshabilitar todos los
              cookies. Sin embargo, las cookies le permitirán tomar ventaja de
              las características más benéficas que le ofrecemos, por lo que le
              recomendamos que las deje activadas. <br />
              <br />
              I. Transferencias <br />
              <br />
              GRUPO ENLACCE solamente transfiere datos personales en los
              supuestos establecidos en el artículo 37 de la Ley Federal de
              Protección de Datos Personales en Posesión de Particulares.
              <br /> <br /> J. Cambios a este Aviso de Privacidad.
              <br />
              <br /> GRUPO ENLACCE podrá en cualquier momento actualizar este
              aviso de privacidad. En el caso de que se produzcan cambios
              sustanciales a este aviso, lo comunicaremos a través de nuestras
              páginas de internet señaladas en el inciso A de este Aviso de
              Privacidad. Por lo anterior, le sugerimos visitar periódicamente
              este aviso de privacidad para estar enterado de cualquier
              actualización. Me doy por enterado y acepto los términos y
              condiciones establecidas en el presente Aviso de Privacidad. En
              caso de alguna inconformidad o queja sobre el tratamiento de sus
              datos personales, usted se puede dirigir al Instituto Nacional de
              Transparencia, Acceso a la Información y Protección de Datos
              Personales. <br />
              <br />
            </p>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignItems="flex-start"
            >
              <strong>
                Fecha de última actualización: 12 de Septiembre de 2020
              </strong>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
