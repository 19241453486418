import React, { useState } from "react"
import { Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import HomeIcon from "../../images/house.svg"
import ManIcon from "../../images/man.svg"
import StartIcon from "../../images/star.svg"
import DefensaIcon from "../../images/scale.svg"
import CoinIcon from "../../images/coin.svg"
import CalculatorIcon from "../../images/calculator.svg"
import { logout } from "../../utils/auth"
import { navigate } from "@reach/router"

const Header = props => {
  const images = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "LogoEnlacce.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      menu: file(relativePath: { eq: "menu.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { logOut } = props
  const [items, setItems] = useState(false)

  const changeItems = bool => {
    if (bool) {
      setItems(bool)
    }
    setTimeout(() => {
      setItems(bool)
    }, 300)
  }

  return (
    <header
      css={css`
        top: 0;
        height: 15%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        z-index: 2;
      `}
    >
      <div
        css={css`
          width: 90%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <a href="/">
          <img
            css={css`
              width: 60%;
              height: auto;
            `}
            src={images.logo.sharp.fluid.src}
            alt="Logo ENLACCE"
          />
        </a>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          {logOut && (
            <Typography
              css={css`
                color: #1b5196;
                font-size: 30px !important;
                font-weight: 700 !important;
                padding-right: 20px;
                cursor: pointer;
              `}
              onClick={() => logout(() => navigate("/Login"))}
            >
              LOGOUT
            </Typography>
          )}
          <div
            css={css`
              width: 60px;
              height: 60px;
              & :hover > div {
                opacity: 1;
              }
            `}
            onMouseOver={() => changeItems(true)}
            onMouseLeave={() => changeItems(false)}
          >
            <Image
              css={css`
                width: 60px;
                height: auto;
              `}
              tag="Section"
              fluid={images.menu.sharp.fluid}
              fadeIn="soft"
            ></Image>
            <div
              css={css`
                width: 60px;
                border-radius: 40px;
                margin-top: -60px;
                transition: 0.3s;
                opacity: 0;
                display: flex;
                justify-content: flex-end;
                background-color: #1b5196;
                flex-direction: column;
              `}
            >
              {items && (
                <div>
                  <div>
                    <Image
                      css={css`
                        width: 60px;
                        height: auto;
                      `}
                      tag="Section"
                      fluid={images.menu.sharp.fluid}
                      fadeIn="soft"
                    ></Image>
                  </div>
                  <div
                    css={css`
                      display: flex;
                      justify-content: flex-end;
                      flex-direction: column;
                    `}
                  >
                    <Link
                      to="/"
                      css={css`
                        height: 50px;
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 5px;
                        text-decoration: none;
                      `}
                    >
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 500 }}
                        css={css`
                          color: #1b5196;
                          white-space: nowrap;
                          font-size: 50px;
                          padding-right: 10px;
                        `}
                      >
                        Home
                      </Typography>
                      <div
                        css={css`
                          min-width: 60px !important;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <img src={HomeIcon} alt="HomeIcon" />
                      </div>
                    </Link>
                    <Link
                      to="/app/home#Recursos_Humanos"
                      css={css`
                        height: 50px;
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 5px;
                        text-decoration: none;
                      `}
                    >
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 500 }}
                        css={css`
                          color: #1b5196;
                          white-space: nowrap;
                          font-size: 50px;
                          padding-right: 10px;
                        `}
                      >
                        RH
                      </Typography>
                      <div
                        css={css`
                          min-width: 60px !important;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <img src={ManIcon} alt="ManIcon" />
                      </div>
                    </Link>
                    <Link
                      to="/app/home#Valores_Agregados"
                      css={css`
                        height: 50px;
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 5px;
                        text-decoration: none;
                      `}
                    >
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 500 }}
                        css={css`
                          color: #1b5196;
                          white-space: nowrap;
                          font-size: 50px;
                          padding-right: 10px;
                        `}
                      >
                        Valores agregados
                      </Typography>
                      <div
                        css={css`
                          min-width: 60px !important;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <img src={StartIcon} alt="Start Icon" />
                      </div>
                    </Link>
                    <Link
                      to="/app/home#Defensa_Legal"
                      css={css`
                        height: 50px;
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 5px;
                        text-decoration: none;
                      `}
                    >
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 500 }}
                        css={css`
                          color: #1b5196;
                          white-space: nowrap;
                          font-size: 50px;
                          padding-right: 10px;
                        `}
                      >
                        Defensa legal
                      </Typography>
                      <div
                        css={css`
                          min-width: 60px !important;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <img src={DefensaIcon} alt="Defensa legal icon" />
                      </div>
                    </Link>
                    <Link
                      to="/app/home#Consultoria_Fiscal"
                      css={css`
                        height: 50px;
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 5px;
                        text-decoration: none;
                      `}
                    >
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 500 }}
                        css={css`
                          color: #1b5196;
                          white-space: nowrap;
                          font-size: 50px;
                          padding-right: 10px;
                        `}
                      >
                        Consultoría fiscal
                      </Typography>
                      <div
                        css={css`
                          min-width: 60px !important;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <img src={CoinIcon} alt="CoinIcon" />
                      </div>
                    </Link>
                    {/* <Link
                      to="/"
                      css={css`
                        height: 50px;
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 5px;
                        text-decoration: none;
                      `}
                    >
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 500 }}
                        css={css`
                          color: #1b5196;
                          white-space: nowrap;
                          font-size: 50px;
                          padding-right: 10px;
                        `}
                      >
                        Contacto
                      </Typography>
                      <div
                        css={css`
                          min-width: 60px !important;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <img src={airPlaneIcon} alt="airPlaneIcon"/>
                      </div>
                    </Link> */}
                    <Link
                      to="/app/calculator"
                      css={css`
                        height: 50px;
                        width: auto;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 5px;
                        text-decoration: none;
                      `}
                    >
                      <Typography
                        style={{ fontSize: "20px", fontWeight: 500 }}
                        css={css`
                          color: #1b5196;
                          white-space: nowrap;
                          font-size: 50px;
                          padding-right: 10px;
                        `}
                      >
                        Simulador
                      </Typography>
                      <div
                        css={css`
                          min-width: 60px !important;
                          height: 100%;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                        `}
                      >
                        <img src={CalculatorIcon} alt="CalculatorIcon" />
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {items ? (
          <div
            css={css`
              position: fixed;
              top: 0;
              left: 0;
              width: 100%;
              height: 110vh;
              background-color: white;
              opacity: 80%;
              z-index: -1;
              transition: 20s;
              left: 0;
            `}
          />
        ) : (
          false
        )}
      </div>
    </header>
  )
}

export default Header
