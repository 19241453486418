/** Calcula por grupo */
export const CALCULATE_GROUP = "/cotizador/group"

/** Calcula por persona */
export const CALCULATE_PERSON = "/cotizador/single"

/**Obtiene todos los datos calculados */
export const GET_CALCULATE = "/cotizador/queryAll"

/**Elimina todas las cotizaciones ralizadas */
export const DELETE_COTIZACION = "/cotizador/deleteAll"

/** Obtiene todos los valores */
export const GET_VALUES = "/valores/queryAll"

/** Editar los valores */
export const PUT_VALUES = "/valores/update"

/** Obtiene todos los ISR */
export const GET_ISR = "/isr/queryAll"

/** Agrega un ISR*/
export const POST_ISR = "/isr/aggregate"

/** Editar un ISR*/
export const PUT_ISR = "/isr/update"

/** Obtiene todos los subsidios*/
export const GET_SUBSIDES = "/Subcidio/queryAll"

/** Agrega un subsidio*/
export const POST_SUBSIDES = "/subcidio/aggregate"

/** Editar un subsidio*/
export const PUT_SUBSIDES = "/subcidio/update"

/** Obtiene todos los usuarios*/
export const GET_USERS = "/users/queryAll"

/** Agrega un usuario*/
export const POST_USERS = "/users/aggregate"

/** Editar un usuario*/
export const PUT_USERS = "/users/update"

/** Envia la cotizacion por correo*/
export const SEND_EMAIL = "/email/sendEmail"



/* Login */
export const SEND_LOGIN = "/security/LogIn"

/*Envio de formulario contacto */

export const SEND_CONTACT_FORM = "/contacto/sendEmailContacto"