import React, { useState, useEffect } from "react"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import MuiDialogContent from "@material-ui/core/DialogContent"
import { Grid, TextField, Button, Typography } from "@material-ui/core"
import InputLabel from "@material-ui/core/InputLabel"

/* NPM FILES */
import * as validator from "validator"

/* LOCAL FILES */
import UseApi from "../../hooks/useApi"
import { SEND_CONTACT_FORM } from "../../constanst/endpoints"
import CloseIcon from "../../images/close.svg"
import AirPlaneIcon from "../../images/airplane.svg"
import Progress from "../Progress"
import Modal from "../Modal"

import { css } from "@emotion/core"
const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
})
const useStyles = makeStyles(theme => ({
  TitleModal: {
    color: "#1B5196",
    textAlign: "center",
    font: "800 32px/38px Helvetica",
    marginTop: "1rem",
  },
  SubtitleText: {
    font: "400 20px/24px Helvetica",
  },
  Label: {
    color: "#000000",
    marginTop: "1rem",
    font: "400 17px/23px Roboto",
  },
  inputcustom: {
    color: "#000000",
    "& label.Mui-focused": {
      color: "#000000",
    },
    "& ::label": {
      color: "red",
    },
    "&:focus": {
      borderColor: " #1B5196",
      transition: "border-color 0.3s ease-out",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#71BFD0",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#71BFD0",
    },
    "& ::placeholder": {
      color: "#BFBFBF",
    },
    "& .MuiSelect-icon": {
      color: "#1B5196",
    },
    "& .MuiSelect-root": {
      color: "#000000",
    },
    "& .MuiInputBase-input": {
      color: "#000000",
    },
    "& .MuiInput-input": {
      color: "#000000",
    },
    "& .MuiTextField-root": {
      color: "#000000",
    },
  },
  IconClose: {
    marginBottom: "-1rem",
    cursor: "pointer",
  },
}))

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false)
  const classes = useStyles()
  const [ErroeEmail, setErroeEmail] = useState(false)
  const [DisabledButton, setDisabledButton] = useState(true)
  const [Nombre, setNombre] = useState("")
  const [Correo, setCorreo] = useState("")
  const [Telefono, setTelefono] = useState(0)
  const [Descripccion, setDescripccion] = useState("")
  const [showProgress, setShowProgress] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [MensajeModal, setMensajeModal] = useState("")

  const { response, post } = UseApi()

  const ValidateButton = () => {
    if (
      ErroeEmail === true ||
      Nombre === "" ||
      Correo === "" ||
      Nombre.length < 2 ||
      Telefono.length < 10 ||
      Descripccion === "" ||
      Descripccion.length < 10
    ) {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setshowModal(false)
  }
  const HandleChangeName = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (/^([A-Z a-z])*$/.test(caracteravalidar) === true) {
      setNombre(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setNombre(sustrae)
    }
    ValidateButton()
  }
  const HandleChangeEmail = e => {
    setCorreo(e.target.value)
    setErroeEmail(!validator.isEmail(e.target.value))
    ValidateButton()
  }
  const HandleChangeTel = e => {
    const caracteravalidar = e.target.value.charAt(e.target.value.length - 1)
    if (validator.isNumeric(caracteravalidar) === true) {
      setTelefono(e.target.value)
    } else {
      const sustrae = e.target.value.substring(0, e.target.value.length - 1)
      setTelefono(sustrae)
    }
    ValidateButton()
  }

  const HandleChangedescription = e => {
    setDescripccion(e.target.value)
    ValidateButton()
  }

  const StyledButton = withStyles({
    root: {
      background: "#1B5196",
      borderRadius: "27px",
      border: 0,
      color: "white",
      height: 48,
      width: "182px",
      padding: "0 30px",
      boxShadow: "0px 3px 6px #0000006A",
    },
    label: {
      textTransform: "capitalize",
      fontFamily: "Helvetica",
      font: "400 20px/24px Helvetica Neue",
    },
  })(Button)

  useEffect(() => {
    if (response.headerResponse) {
      setShowProgress(false)
      const { headerResponse } = response
      const { code } = headerResponse
      if (code === 200) {
        setMensajeModal(headerResponse.message)
        setshowModal(true)
      }
    }
  }, [response])

  const SendData = async () => {
    const query = {
      endpoint: SEND_CONTACT_FORM,

      data: {
        nombre: Nombre,
        telefono: Telefono,
        correo: Correo,
        comentario: Descripccion,
      },
    }
    setShowProgress(true)
    await post(query)
  }
  return (
    <div>
      {showModal ? (
        <Modal noButton>
          <Typography className={classes.cardText}>{MensajeModal}</Typography>
          <StyledButton onClick={() => handleClose()}>
            Cerrar
          </StyledButton>
        </Modal>
      ) : (
        false
      )}
      <Progress show={showProgress}></Progress>
      {props.type === "Button" ? (
        <Button
          variant="contained"
          css={css`
            border-radius: 50px !important;
            margin-top: 40px !important;
            width: 182px;
            height: 45px;
            font-size: 20px !important;
            background-color: #1b5196 !important;
            color: #fff !important;
            @media (max-width: 768px) {
              display: none;
            }
          `}
          onClick={() => handleClickOpen()}
        >
          CONTÁCTANOS
        </Button>
      ) : props.type === "Icon" ? (
        <img
          src={AirPlaneIcon}
          alt="airPlaneIcon"
          onClick={() => handleClickOpen()}
        />
      ) : (
        <Typography
          style={{ fontSize: "20px", fontWeight: 500 }}
          css={css`
            color: #1b5196;
            white-space: nowrap;
            font-size: 50px;
            padding-right: 10px;
          `}
          onClick={() => handleClickOpen()}
        >
          Contacto
        </Typography>
      )}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"md"}
      >
        <Grid
          xs={12}
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-start"
        >
          <img
            src={CloseIcon}
            alt="CloseIcon"
            onClick={() => handleClose()}
            className={classes.IconClose}
          />
        </Grid>
        <Grid xs={12}>
          <Typography className={classes.TitleModal}>
            ¡Estamos para ayudarte!
          </Typography>
        </Grid>
        <DialogContent>
          <Grid
            xs={12}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Typography className={classes.SubtitleText}>
              Déjanos saber tus dudas, nos pondremos muy pronto en contacto
              contigo.
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            md={12}
          >
            <Grid item xs={10} md={9}>
              <InputLabel className={classes.Label}>Nombre:</InputLabel>
              <TextField
                placeholder="Nombre"
                id="Nombre"
                name="Nombre"
                fullWidth
                className={classes.inputcustom}
                onChange={HandleChangeName}
                error={Nombre === "" ? false : Nombre.length < 3 ? true : false}
              />
            </Grid>
            <Grid item xs={10} md={9}>
              <InputLabel className={classes.Label}>
                Correo electrónico:
              </InputLabel>
              <TextField
                placeholder="Correo electrónico"
                id="Correo electrónico"
                name="Correo electrónico"
                fullWidth
                className={classes.inputcustom}
                onChange={HandleChangeEmail}
                error={ErroeEmail}
              />
            </Grid>
            <Grid item xs={10} md={9}>
              <InputLabel className={classes.Label}>Teléfono:</InputLabel>
              <TextField
                placeholder="Teléfono"
                id="Teléfono"
                name="Teléfono"
                fullWidth
                inputProps={{ maxLength: 10 }}
                className={classes.inputcustom}
                onChange={HandleChangeTel}
                error={
                  Telefono.length < 10 ? true : Telefono > 10 ? true : false
                }
              />
            </Grid>
            <Grid item xs={10} md={9}>
              <InputLabel className={classes.Label}>Comentario:</InputLabel>
              <TextField
                placeholder="Comentario"
                id="Comentario"
                name="Comentario"
                fullWidth
                className={classes.inputcustom}
                onChange={HandleChangedescription}
                rows={6}
                error={
                  Descripccion === ""
                    ? false
                    : Descripccion.length < 10
                    ? true
                    : false
                }
                multiline
              />
            </Grid>
            <Grid
              xs={10}
              md={12}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Button
                variant="contained"
                css={css`
                  border-radius: 50px !important;
                  margin-top: 40px !important;
                  width: 182px;
                  height: 45px;
                  font-size: 20px !important;
                  background-color: #1b5196 !important;
                  color: #fff !important;
                  @media (max-width: 768px) {
                    display: none;
                  }
                `}
                onClick={() => SendData()}
                disabled={DisabledButton}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}
