module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/carlos.buendia/enlaceefront/src/components/layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Enlacce","short_name":"enlacce","start_url":"/","background_color":"#FFF","theme_color":"#94AFCB","display":"standalone","icon":"src/images/Logo@2x.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"770eae54c36f59e8883e943ef6e762ea"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
