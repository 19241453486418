import React, { useState } from "react"
import { Typography } from "@material-ui/core"
import { css } from "@emotion/core"
import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm"
import { Link } from "gatsby"
import { logout } from "../utils/auth"
import HeaderLogin from "./Headers/HeaderAcces"
import HeaderCalculator from "./Headers/HeaderLogin.js"
import PersonIcon from "../images/man.svg"
import EnlaceIcon from "../images/Trazado_1403.svg"
import FlagIcon from "../images/flag.svg"
import PinIcon from "../images/location.svg"
import FormContact from "../components/FormContact/FormContact"

const Header = props => {
  const images = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "LogoEnlacce.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      menu: file(relativePath: { eq: "menu.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const { logOut, InfoPath } = props
  const [items, setItems] = useState(false)

  const changeItems = bool => {
    if (bool) {
      setItems(bool)
    }
    setTimeout(() => {
      setItems(bool)
    }, 300)
  }

  return (
    <div>
      {InfoPath.pathname === "/app/home" ? (
        <HeaderLogin logOut={logOut} />
      ) : InfoPath.pathname === "/" ? (
        <header
          css={css`
            top: 0;
            height: 15%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            z-index: 2;
            float: left;
          `}
        >
          <div
            css={css`
              width: 90%;
              display: flex;
              align-items: center;
              justify-content: space-between;
            `}
          >
            <a href="/">
              <img
                css={css`
                  width: 60%;
                  height: auto;
                `}
                src={images.logo.sharp.fluid.src}
                alt="LogoENlacee"
              />
            </a>
            <div
              css={css`
                display: flex;
                align-items: center;
              `}
            >
              {logOut && (
                <Typography
                  css={css`
                    color: #1b5196;
                    font-size: 30px !important;
                    font-weight: 700 !important;
                    padding-right: 20px;
                  `}
                  onClick={() => logout(() => (window.location = "/Login"))}
                >
                  LOGOUT
                </Typography>
              )}
              <div
                css={css`
                  width: 60px;
                  height: 60px;
                  & :hover > div {
                    opacity: 1;
                  }
                `}
                onMouseOver={() => changeItems(true)}
                onMouseLeave={() => changeItems(false)}
                role="div"
              >
                <Image
                  css={css`
                    width: 60px;
                    height: auto;
                  `}
                  tag="Section"
                  fluid={images.menu.sharp.fluid}
                  fadeIn="soft"
                ></Image>
                <div
                  css={css`
                    width: 60px;
                    border-radius: 40px;
                    margin-top: -60px;
                    transition: 0.3s;
                    opacity: 0;
                    display: flex;
                    justify-content: flex-end;
                    background-color: #1b5196;
                    flex-direction: column;
                  `}
                >
                  {items && (
                    <div>
                      <div>
                        <Image
                          css={css`
                            width: 60px;
                            height: auto;
                          `}
                          tag="Section"
                          fluid={images.menu.sharp.fluid}
                          fadeIn="soft"
                        ></Image>
                      </div>

                      <div
                        css={css`
                          display: flex;
                          justify-content: flex-end;
                          flex-direction: column;
                          z-index: 100;
                        `}
                      >
                        <Link
                          to="/#Enlacce"
                          css={css`
                            height: 50px;
                            width: auto;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 5px;
                            text-decoration: none;
                          `}
                        >
                          <Typography
                            style={{ fontSize: "20px", fontWeight: 500 }}
                            css={css`
                              color: #1b5196;
                              white-space: nowrap;
                              font-size: 50px;
                              padding-right: 10px;
                            `}
                          >
                            Enlacce
                          </Typography>
                          <div
                            css={css`
                              min-width: 60px !important;
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <img src={EnlaceIcon} alt="EnlaceIcon" />
                          </div>
                        </Link>
                        <Link
                          to="/#Areas_Asesoria"
                          css={css`
                            height: 50px;
                            width: auto;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 5px;
                            text-decoration: none;
                          `}
                        >
                          <Typography
                            style={{ fontSize: "20px", fontWeight: 500 }}
                            css={css`
                              color: #1b5196;
                              white-space: nowrap;
                              font-size: 50px;
                              padding-right: 10px;
                            `}
                          >
                            Áreas de asesoría
                          </Typography>
                          <div
                            css={css`
                              min-width: 60px !important;
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <img src={FlagIcon} alt="FlagIcon" />
                          </div>
                        </Link>
                        <Link
                          to="/#Fortalezas"
                          css={css`
                            height: 50px;
                            width: auto;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 5px;
                            text-decoration: none;
                          `}
                        >
                          <Typography
                            style={{ fontSize: "20px", fontWeight: 500 }}
                            css={css`
                              color: #1b5196;
                              white-space: nowrap;
                              font-size: 50px;
                              padding-right: 10px;
                            `}
                          >
                            Fortalezas
                          </Typography>
                          <div
                            css={css`
                              min-width: 60px !important;
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <AccessAlarmIcon
                              style={{ fontSize: 30, color: "#ffffff" }}
                            />
                          </div>
                        </Link>
                        <Link
                          to="/#Cobertura"
                          css={css`
                            height: 50px;
                            width: auto;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 5px;
                            text-decoration: none;
                          `}
                        >
                          <Typography
                            style={{ fontSize: "20px", fontWeight: 500 }}
                            css={css`
                              color: #1b5196;
                              white-space: nowrap;
                              font-size: 50px;
                              padding-right: 10px;
                            `}
                          >
                            Cobertura
                          </Typography>
                          <div
                            css={css`
                              min-width: 60px !important;
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <img src={PinIcon} alt="PinIcon" />
                          </div>
                        </Link>
                        <Link
                          to="/"
                          css={css`
                            height: 50px;
                            width: auto;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 5px;
                            text-decoration: none;
                          `}
                        >
                          <FormContact type="Text" />
                          <div
                            css={css`
                              min-width: 60px !important;
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <FormContact type="Icon" />
                          </div>
                        </Link>
                        <Link
                          to="/Login"
                          css={css`
                            height: 50px;
                            width: auto;
                            display: flex;
                            justify-content: flex-end;
                            align-items: center;
                            margin-bottom: 5px;
                            text-decoration: none;
                          `}
                        >
                          <Typography
                            style={{ fontSize: "20px", fontWeight: 500 }}
                            css={css`
                              color: #1b5196;
                              white-space: nowrap;
                              font-size: 50px;
                              padding-right: 10px;
                            `}
                          >
                            Partners login
                          </Typography>
                          <div
                            css={css`
                              min-width: 60px !important;
                              height: 100%;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            `}
                          >
                            <img src={PersonIcon} alt="PersonIcon" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </header>
      ) : (
        <HeaderCalculator logOut={logOut} />
      )}
      {items ? (
        <div
          css={css`
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 110vh;
            background-color: white;
            opacity: 80%;
            z-index: 1;
            transition: 20s;
            left: 0;
          `}
        />
      ) : (
        false
      )}
    </div>
  )
}

export default Header
