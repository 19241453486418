import { useState } from "react"
// import { useDispatch } from 'react-redux';
import axios from "axios"
// import Session from '../util/session';
// import { useHistory } from 'react-router-dom';

function UseApi() {
  const [response, setData] = useState({})
  const [errorApi, setErrorApi] = useState("")
  const [status, setSatus] = useState("")
  // const dispatch = useDispatch();
  // let history = useHistory();

  //const ip = "enlace.springlabs.net"
  const ip = "f81f6af0.ngrok.io"


  const get = async ({ endpoint, headers, store = false, action, feature }) => {
    const url = `https://${ip}${endpoint}`
    try {
      const instance = await axios.request({
        url,
        method: "get",
        headers,
        body: {},
      })
      const response = await instance.data
      if (store) {
        // await dispatch(
        // 	action({
        // 		payload: response.payload,
        // 		feature,
        // 	})
        // );
      }
      setSatus(instance.status)
      await setData(response)
    } catch (error) {
      setErrorApi("Se produjo un error")
      const errorname = error + ""
      const Errornum = errorname.indexOf("401")
      if (Errornum !== -1) {
        // Session.signout(() => {
        // 	history.push('/login');
        // });
      }
    }
  }
  const post = async ({
    endpoint,
    headers,
    data,
    store = false,
    action,
    feature,
  }) => {
    const url = `https://${ip}${endpoint}`
    try {
      const instance = await axios.request({
        url,
        method: "POST",
        data,
        headers,
      })

      const response = await instance.data
      const { headerResponse } = response

      if (headerResponse.code !== 200) {
        setErrorApi(headerResponse.message)
      } else {
        await setData(response)
      }
      setSatus(instance.status)
    } catch (error) {
      setErrorApi("Se produjo un error")
      const errorname = error + ""
      const Errornum = errorname.indexOf("401")
      if (Errornum !== -1) {
        // Session.signout(() => {
        // 	history.push('/login');
        // });
      }
    }
  }
  const put = async ({ endpoint, headers, data }) => {
    const url = `https://${ip}${endpoint}`
    try {
      const instance = await axios.request({
        url,
        method: "PUT",
        data,
        headers,
      })
      const response = await instance.data
      setData(response)
      setSatus(instance.status)
    } catch (error) {
      const errorname = error + ""
      const Errornum = errorname.indexOf("401")
      if (Errornum !== -1) {
        // Session.signout(() => {
        // 	history.push('/login');
        // });
      }
    }
  }
  const deleted = async ({ endpoint, headers, data }) => {
    const url = `https://${ip}${endpoint}`
    try {
      const instance = await axios.request({
        url,
        method: "DELETE",
        data,
        headers,
      })
      const response = await instance.data
      setData(response)
      setSatus(instance.status)
    } catch (error) {
      const errorname = error + ""
      const Errornum = errorname.indexOf("401")
      if (Errornum !== -1) {
        // Session.signout(() => {
        // 	history.push('/login');
        // });
      }
    }
  }
  const resetVal = () => {
    setData({})
    setErrorApi("")
  }
  return { response, get, post, put, deleted, errorApi, resetVal }
}

export default UseApi
