import React, { useState } from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Facebook from "../images/Facebook.svg"
import { css } from "@emotion/core"
import ModalPrivacidad from "../components/ModalPrivacidad"

const useStyles = makeStyles(theme => ({
  ContenedorBlanco: {
    width: "100%",
    height: "812px",
    backgroundColor: "#F2F2F2",
    [theme.breakpoints.down("xs")]: {
      height: "1012px",
    },
  },
  DividerAzul: {
    width: "44px",
    height: "0px",
    border: "2px solid #1B5196",
    marginTop: "4px",
    marginBottom: "3%",
  },
  Contenedor1: {
    // backgroundColor:'red',
    marginTop: "5%",
    height: "85%",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  Contenedor11: {
    marginTop: "5%",
    height: "85%",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  ContenedorFinal: {
    marginTop: "5%",
    height: "85%",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  Titulo: {
    color: "#71BFD0",
    fontSize: "22px",
  },
  Texto: {
    color: "#1B5196",
    fontSize: "20px",
    marginTop: "1rem",
  },
  Titulo2: {
    color: "#71BFD0",
    fontSize: "22px",
    marginTop: "5rem",
  },
  Avisos: {
    textDecoration: "underline",
    color: "#71BFD0",
    fontSize: "20px",
    marginLeft: "2rem",
    cursor: "pointer",
  },
  TextoCopy: {
    color: "#1B5196",
    fontSize: "20px",
    marginTop: "1rem",
    marginLeft: "2rem",
  },
  Imagen: {
    marginLeft: "1rem",
  },
}))

function SectionOnePage2(props) {
  const classes = useStyles()
  const { logOut } = props
  const [openModal, setOpenModal] = useState(false)
  const handleClose = () => {
    setOpenModal(false)
  }
  const handleClickOpenModal = () => {
    setOpenModal(true)
  }
  return (
    <div>
      {logOut ? (
        <Grid
          css={css`
            width: 100%;
            height: 60px;
            background-color: rgba(131, 160, 186, 0.6);
            display: flex;
            justify-content: flex-end;
            align-items: center;
          `}
        >
          <Typography
            css={css`
              color: #ffffff8e;
              font-size: 20px !important;
              padding-right: 20px;
            `}
          >
            Powered by SPRINGLABS
          </Typography>
        </Grid>
      ) : (
        <Grid container className={classes.ContenedorBlanco}>
          <Grid
            container
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid xs={12} md={3} lg={3} xl={3} className={classes.Contenedor11}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Typography
                  className={classes.Avisos}
                  onClick={handleClickOpenModal}
                >
                  Aviso de privacidad, términos y <br /> condiciones.
                </Typography>
                <Typography className={classes.TextoCopy}>
                  © 2020 Todos los derechos <br />
                  reservados. Powered by <br />
                  Springlabs.
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={10} md={3} lg={3} xl={3} className={classes.Contenedor1}>
              <Typography className={classes.Titulo}>OFICINAS</Typography>
              <Typography className={classes.DividerAzul} />
              <Typography className={classes.Texto}>
                Monterrey <br /> Guadalajara <br /> Ciudad de México <br />{" "}
                Mérida
              </Typography>
              <Typography className={classes.Titulo2}>Teléfonos</Typography>
              <Typography className={classes.DividerAzul} />
              <Typography className={classes.Texto}>
                81 2092 0593 <br /> 81 2092 0591
              </Typography>
              <Typography className={classes.Titulo2}>Correo</Typography>
              <Typography className={classes.DividerAzul} />
              <Typography className={classes.Texto}>
                coordinacion@enlacce.com
              </Typography>
            </Grid>
            <Grid xs={10} md={3} lg={3} xl={3} className={classes.Contenedor1}>
              <Typography className={classes.Titulo}>CORRESPONSALES</Typography>
              <Typography className={classes.DividerAzul} />
              <Typography className={classes.Texto}>
                Mexicali <br /> Tijuana <br /> Culiacán <br /> Chihuahua Cd.
                Juaréz <br /> Puerto Vallarta <br /> Laredo <br /> Cd. Victoria
                Sabinas, Coahuila <br /> Salitillo <br /> S.L.P <br /> Torreón
                Monclova <br /> Los Mochis <br /> Puebla <br /> Cancún
              </Typography>
            </Grid>
            <Grid
              xs={10}
              md={3}
              lg={3}
              xl={3}
              className={classes.ContenedorFinal}
            >
              <Typography className={classes.Titulo}>Síguenos</Typography>
              <Typography className={classes.DividerAzul} />
              <a
                href="https://www.facebook.com/GrupoEnlacce"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} className={classes.Imagen} alt="Facebook" />
              </a>
            </Grid>
          </Grid>
          <ModalPrivacidad open={openModal} handleClose={handleClose} />
        </Grid>
      )}
    </div>
  )
}

export default SectionOnePage2
