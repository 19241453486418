/**
 *  @version 1.0.1
 *  @author Springlabs - alexalemanhdz
 *  @description Hook para modales
 * 	@process 3
*/

import { useState } from'react';

function useModal() {
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return [open, handleOpen, handleClose];
}

export default useModal;